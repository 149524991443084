<template>
  <div class="container-fluid px-xl-4 py-4">
  <div class="scan-barcode-page">
    <b-row>
      <b-col class="col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="showScanner">
        <StreamBarcodeReader @decode="onDecode" @error="onError"></StreamBarcodeReader>
      </b-col>
    </b-row>
  </div>
  </div>  
</template>

<script>
export default {
  data() {
    return {
      showScanner: true,
    };
  },
  methods: {
    async onDecode(result) {
      // alert(result);
      this.$router.push({ name: "Part list", query: { id: result } });
    },
    onError(error) {
      console.log(error);
    },
  },
};
</script>